import axios from "axios";
import moment from "moment";
import { isDeployedCode } from "../utils/server-helpers";

export function schedulePosthook<T extends (...args: any) => any>(p: {
  bifrostFn: T;
  webhookData: Parameters<T>[0];
  minutes: number;
  apiKey: string;
}) {
  if (Object.keys(p.webhookData).length !== 1 || !p.webhookData.data) {
    throw new Error("For type safety purposes, webhook data must be an object with a single property of 'data'");
  }

  if (!isDeployedCode()) {
    return;
  }

  return axios.post(
    "https://api.posthook.io/v1/hooks",
    {
      path: `api-bifrost/${p.bifrostFn.name}`,
      postAt: moment().add(p.minutes, "minutes").toISOString(),
      data: p.webhookData.data
    },
    { headers: { ["X-API-Key"]: p.apiKey } }
  );
}

// i18n certified - complete

import { BifrostSubscription, createBifrostSubscription, UnpackBifrostSubscription } from "@ollie-sports/react-bifrost";
import { ObjectKeys } from "../utils";
import _ from "lodash";
export type UnpackSubs<T extends Record<PropertyKey, any>> = {
  [P in keyof T]: UnpackBifrostSubscription<T[P]>;
};
export function combineBifrostSubscriptions<T extends Record<string, BifrostSubscription<any>>>(subs: T) {
  const currentInstanceData: UnpackSubs<T> = _.mapValues(subs, () => [] as any);
  const hasFiredOnce: Record<keyof T, boolean> = _.mapValues(subs, () => false);
  ObjectKeys(subs).forEach(key => {
    currentInstanceData[key] = [] as any;
  });
  const disposeFns: Function[] = [];
  const instance = createBifrostSubscription<UnpackSubs<T>>({
    dispose: () => {
      try {
        disposeFns.forEach(fn => fn());
      } catch (e) {
        console.error(e);
      }
    }
  });
  ObjectKeys(subs).forEach(key => {
    disposeFns.push(subs[key].dispose);
    subs[key].onData(data => {
      currentInstanceData[key] = data;
      hasFiredOnce[key] = true;
      if (ObjectKeys(hasFiredOnce).every(a => hasFiredOnce[a])) {
        instance.nextData({ ...currentInstanceData });
      }
    });

    subs[key].onError(err => {
      instance.nextError(err);
    });
  });
  return instance;
}

// i18n certified - complete

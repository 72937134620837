import * as express from "express";
import _ from "lodash";
import { getServerHelpers } from "../helpers";

export async function validateSelfAccountId(req: express.Request, selfAccountId: string) {
  // SERVER_ONLY_TOGGLE
  const { uid } = await validateToken(req);

  if (uid !== selfAccountId) {
    throw {
      statusCode: 401,
      error: new Error("You may not spoof a user other than the one represented in your auth token")
    };
  }
  // SERVER_ONLY_TOGGLE
}

export async function validateToken(req: express.Request): Promise<{ uid: string }> {
  // SERVER_ONLY_TOGGLE

  if (!req.headers.authorization) {
    throw {
      statusCode: 401,
      error: new Error("No authorization header present on request")
    };
  }

  try {
    return verifyIdToken(req.headers.authorization);
  } catch (e) {
    throw {
      statusCode: 401,
      error: new Error("Invalid auth token")
    };
  }
  // SERVER_ONLY_TOGGLE
}

export async function verifyIdToken(token: string) {
  // SERVER_ONLY_TOGGLE
  const { appFirebaseAdminApp } = getServerHelpers();

  const decodedToken = await appFirebaseAdminApp.auth().verifyIdToken(token);

  return {
    uid: decodedToken.uid
  };
  // SERVER_ONLY_TOGGLE
}

export async function validateTokenAndEnsureSelfAccountIdMatches(req: express.Request): Promise<void> {
  // SERVER_ONLY_TOGGLE
  return validateUserIdentity({ request: req, propertyToCheck: "selfAccountId" });
  // SERVER_ONLY_TOGGLE
}

export async function validateUserIdentity(p: {
  request: express.Request;
  propertyToCheck: "accountId" | "selfAccountId" | "userAccountId" | "poll.creatorAccountId";
}) {
  // SERVER_ONLY_TOGGLE
  let r = await validateToken(p.request);

  //TODO: Eventually we'll have to beef this up for super admins too
  if (_.get(p.request.body, p.propertyToCheck) !== r.uid) {
    throw {
      statusCode: 401,
      error: new Error(`The ${p.propertyToCheck} does not match the auth token`)
    };
  }
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete

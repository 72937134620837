export type TypeEqualsType<T, S> = [T] extends [S] ? ([S] extends [T] ? true : false) : false;

export function assertUnreachable(x: never): never {
  throw new Error("Didn't expect to get here");
}
type Primitive = string | Function | number | boolean | Symbol | undefined | null;

type DeepOmitArray<T extends any[], K> = {
  [P in keyof T]: DeepOmit<T[P], K>;
};

export type DeepOmit<T, K> = T extends Primitive
  ? T
  : {
      [P in Exclude<keyof T, K>]: T[P] extends infer TP
        ? TP extends Primitive
          ? TP // leave primitives and functions alone
          : TP extends any[]
          ? DeepOmitArray<TP, K> // Array special handling
          : DeepOmit<TP, K>
        : never;
    };
// i18n certified - complete

import {
  FirestoreLiftQuerySubscription,
  FirestoreLiftDocSubscription,
  FirestoreLiftDocsSubscription
} from "@ollie-sports/firebase-lift";
import { createBifrostSubscription } from "@ollie-sports/react-bifrost";
export function firestoreLiftQuerySubToBifrostSub<T>(firestoreSub: FirestoreLiftQuerySubscription<T>) {
  let disposeFn: () => void;

  const bifrostSub = createBifrostSubscription<T[]>({
    dispose: () => {
      disposeFn();
    }
  });

  const sub = firestoreSub.subscribe(
    a => {
      bifrostSub.nextData(a.docs);
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFn = sub.unsubscribe;

  return bifrostSub;
}

export function firestoreLiftDocSubToBifrostSub<T>(firestoreSub: FirestoreLiftDocSubscription<T>) {
  let disposeFn: () => void;

  const bifrostSub = createBifrostSubscription<T | null>({
    dispose: () => {
      disposeFn();
    }
  });

  const sub = firestoreSub.subscribe(
    a => {
      bifrostSub.nextData(a);
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFn = sub.unsubscribe;

  return bifrostSub;
}

export function firestoreLiftDocsSubToBifrostSub<T>(firestoreSub: FirestoreLiftDocsSubscription<T>) {
  let disposeFn: () => void;

  const bifrostSub = createBifrostSubscription<Array<T>>({
    dispose: () => {
      disposeFn();
    }
  });

  const sub = firestoreSub.subscribe(
    a => {
      let temp: T[] = [];
      // We filter out any ones that come back as null. Any checks should happen elsewhere
      a.forEach(i => {
        if (i) {
          temp.push(i);
        }
      });
      bifrostSub.nextData(temp);
    },
    e => {
      console.error(e.message);
    }
  );

  disposeFn = sub.unsubscribe;

  return bifrostSub;
}

// i18n certified - complete

import _ from "lodash";
import { PlayerBundle, PrettyPlayerBundle, PlayerBundle__AccountType, Account, Player } from "@ollie-sports/models";
import { getUniversalHelpers } from "../helpers";

export async function fetchPrettyPlayerBundle(playerBundle: PlayerBundle): Promise<PrettyPlayerBundle | undefined> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  if (!playerBundle) {
    return undefined;
  }

  try {
    const selfAccountId = Object.keys(playerBundle.managingAccounts || {}).find(
      k => playerBundle.managingAccounts?.[k]?.type === PlayerBundle__AccountType.selfAthlete
    );

    // If no self object we just use the data from the profile
    if (!selfAccountId) {
      return {
        playerBundle: playerBundle,
        derived: {
          accountInfo: playerBundle.virtualAthleteAccount,
          accountInfoSource: "playerBundle"
        }
      };
    }

    const account = await h.Account.getDoc(selfAccountId);
    if (!account) {
      throw new Error("Unable to find account");
    }

    return {
      playerBundle: playerBundle,
      derived: {
        accountInfo: {
          email: account.email,
          firstName: account.firstName,
          lastName: account.lastName,
          phoneNumber: account.phoneNumber,
          profileImageUri: account.profileImageUri,
          profileImageUriSmall: account.profileImageUriSmall,
          suffix: account.suffix
        },
        accountInfoSource: "account"
      }
    };
  } catch (e) {
    console.error("Error running fetchPrettyPlayer", e);
    return {
      playerBundle: playerBundle,
      derived: {
        accountInfo: playerBundle.virtualAthleteAccount,
        accountInfoSource: "playerBundle"
      }
    };
  }
}

export async function fetchPrettyPlayerBundleList(profileObjs: PlayerBundle[]) {
  let vals = await Promise.all(profileObjs.map(p => fetchPrettyPlayerBundle(p)));
  return _.sortBy(
    vals,
    v => `${v?.derived.accountInfo.firstName}${v?.derived.accountInfo.lastName}${v?.playerBundle.id}`
  ) as PrettyPlayerBundle[];
}

export function fetchPrettyPlayerBundleFromPlayerBundleAndAccounts(
  playerBundle: PlayerBundle,
  accounts: Record<string, Account>
): PrettyPlayerBundle | undefined {
  const selfAccountId = Object.keys(playerBundle.managingAccounts || {}).find(
    k => playerBundle.managingAccounts?.[k]?.type === PlayerBundle__AccountType.selfAthlete
  );

  // If no self object we just use the data from the profile
  if (!selfAccountId) {
    return {
      playerBundle: playerBundle,
      derived: {
        accountInfo: playerBundle.virtualAthleteAccount,
        accountInfoSource: "playerBundle"
      }
    };
  }

  const account = accounts[selfAccountId];
  if (!account) {
    return {
      playerBundle: playerBundle,
      derived: {
        accountInfo: playerBundle.virtualAthleteAccount,
        accountInfoSource: "playerBundle"
      }
    };
  }

  return {
    playerBundle: playerBundle,
    derived: {
      accountInfo: {
        email: account.email,
        firstName: account.firstName,
        lastName: account.lastName,
        phoneNumber: account.phoneNumber,
        profileImageUri: account.profileImageUri,
        profileImageUriSmall: account.profileImageUriSmall,
        suffix: account.suffix
      },
      accountInfoSource: "account"
    }
  };
}

// i18n certified - complete

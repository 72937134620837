import { License, LICENSE_TYPES, LicenseHistory, AccountId, LicenseId, RevenueCatEntitlements } from "@ollie-sports/models";
import { getServerHelpers, getUniversalHelpers } from "../helpers";
import { BatchTask } from "@ollie-sports/firebase-lift";
import axios from "axios";
import moment from "moment-timezone";
import jsonStableStringify from "json-stable-stringify";

// *************************
// General Purpose functions for all licenses
// *************************

export async function addNoteToLicense(p: {
  licenseId: LicenseId;
  noteText: string;
  actionPerformedByAccountId: AccountId;
}): Promise<BatchTask> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;

  const historyItem: LicenseHistory = {
    id: h.LicenseHistory.generateId(),
    createdAtMS: Date.now(),
    humanReadableSummary: p.noteText,
    licenseId: p.licenseId,
    performedByAccountId: p.actionPerformedByAccountId
  };
  return await h.LicenseHistory.add({ doc: historyItem }, { returnBatchTask: true });
  // SERVER_ONLY_TOGGLE
}

export async function expireLicense(p: {
  licenseId: LicenseId;
  humanReadableReasonForAction: string;
  actionPerformedByAccountId: AccountId;
}): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;

  const license = await ensureLicenseExists(p.licenseId);

  const tasks: BatchTask[] = [];

  tasks.push(
    await h.License.update({ id: p.licenseId, doc: { status: "inactive", expireAtMS: Date.now() } }, { returnBatchTask: true })
  );
  tasks.push(
    await addNoteToLicense({
      licenseId: p.licenseId,
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      noteText: `License Expired. ${p.humanReadableReasonForAction}`
    })
  );

  // Remove any links to entities linked to the license
  // This makes it so we can't easily renew but the billing system assumes a linkedLicenseId is valid if it exists
  if (license.type === LICENSE_TYPES.account) {
    const linkedEntities = await h.AccountPrivate.query({ where: [{ linkedLicenseId: ["==", p.licenseId] }] });
    for (let i = 0; i < linkedEntities.docs.length; i++) {
      const doc = linkedEntities.docs[i];
      tasks.push(
        await h.AccountPrivate.update({ doc: { linkedLicenseId: h._MagicDeleteValue }, id: doc.id }, { returnBatchTask: true })
      );
    }
  }

  // Since teams can be linked to org licenses we also expire them here
  if (license.type === LICENSE_TYPES.team || license.type === LICENSE_TYPES.org) {
    const linkedEntities = await h.Team.query({ where: [{ linkedLicenseId: ["==", p.licenseId] }] });
    for (let i = 0; i < linkedEntities.docs.length; i++) {
      const doc = linkedEntities.docs[i];
      tasks.push(await h.Team.update({ doc: { linkedLicenseId: h._MagicDeleteValue }, id: doc.id }, { returnBatchTask: true }));
    }
  }

  if (license.type === LICENSE_TYPES.org) {
    const linkedEntities = await h.Org.query({ where: [{ linkedLicenseId: ["==", p.licenseId] }] });
    for (let i = 0; i < linkedEntities.docs.length; i++) {
      const doc = linkedEntities.docs[i];
      tasks.push(await h.Org.update({ doc: { linkedLicenseId: h._MagicDeleteValue }, id: doc.id }, { returnBatchTask: true }));
    }
  }

  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function renewLicense(p: {
  licenseId: LicenseId;
  humanReadableReasonForAction: string;
  renewalDetails?: string; // Maybe stripe confirmation code, stuff like that
  newExpireAtMS: number;
  entityType: "team" | "org" | "account";
  entityId: string;
  actionPerformedByAccountId: AccountId;
  couponString?: string;
}): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;

  let currentEntityLicenseId = await getCurrentEntityLinkedLicenseId({ entityId: p.entityId, entityType: p.entityType });
  if (p.licenseId !== currentEntityLicenseId) {
    throw new Error(`Cannot renew license for a ${p.entityId}. Can only renew licenses if they are linked.`);
  }

  if (p.newExpireAtMS < Date.now()) {
    throw new Error("Cannot renew a license for a date in the past");
  }

  const tasks: BatchTask[] = [];

  tasks.push(
    await h.License.update({ id: p.licenseId, doc: { status: "active", expireAtMS: p.newExpireAtMS } }, { returnBatchTask: true })
  );

  tasks.push(
    await addNoteToLicense({
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      licenseId: p.licenseId,
      noteText: `License Renewal. ${p.humanReadableReasonForAction}`
    })
  );

  if (p.renewalDetails) {
    tasks.push(
      await addNoteToLicense({
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        licenseId: p.licenseId,
        noteText: `Renewal details. ${p.renewalDetails}`
      })
    );
  }
  if (p.couponString) {
    tasks.push(
      await addNoteToLicense({
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        licenseId: p.licenseId,
        noteText: p.couponString
      })
    );
  }

  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function linkLicense(p: {
  entityType: "team" | "org" | "account";
  entityId: string;
  licenseId: LicenseId;
  humanReadableReasonForAction: string;
  actionPerformedByAccountId: AccountId;
}): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;
  let tasks: BatchTask[] = [];

  // Create audit for the old license that is being unlinked (if its exists)
  let currentEntityLicenseId = await getCurrentEntityLinkedLicenseId({ entityId: p.entityId, entityType: p.entityType });
  if (currentEntityLicenseId && currentEntityLicenseId !== p.licenseId) {
    tasks.push(
      await addNoteToLicense({
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        licenseId: currentEntityLicenseId,
        noteText: `Unlinked from ${p.entityId}. ${p.licenseId} will become the new linked license`
      })
    );
  }

  tasks.push(
    await addNoteToLicense({
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      licenseId: p.licenseId,
      noteText: `Linking license to ${p.entityId}. ${p.humanReadableReasonForAction}`
    })
  );

  // Actually link the license to its entity
  if (p.entityType === "team") {
    tasks.push(await h.Team.update({ id: p.entityId, doc: { linkedLicenseId: p.licenseId } }, { returnBatchTask: true }));
  } else if (p.entityType === "org") {
    tasks.push(await h.Org.update({ id: p.entityId, doc: { linkedLicenseId: p.licenseId } }, { returnBatchTask: true }));

    // Check all teams belonging to an org. They will get linked as well.
    let t1 = await h.Team.query({ where: [{ orgId: ["==", p.entityId] }] });
    for (let i = 0; i < t1.docs.length; i++) {
      const team = t1.docs[i];
      if (team.linkedLicenseId !== p.licenseId) {
        tasks = [
          ...tasks,
          ...(await linkLicense({
            actionPerformedByAccountId: p.actionPerformedByAccountId,
            entityId: team.id,
            entityType: "team",
            licenseId: p.licenseId,
            humanReadableReasonForAction: `Parent org is linking a license. Link team to the org license.`
          }))
        ];
      }
    }
  } else if (p.entityType === "account") {
    tasks.push(
      await h.AccountPrivate.update({ id: p.entityId, doc: { linkedLicenseId: p.licenseId } }, { returnBatchTask: true })
    );
  }

  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function unlinkLicense(p: {
  entityType: "team" | "org" | "account";
  entityId: string;
  licenseId: LicenseId;
  humanReadableReasonForAction: string;
  actionPerformedByAccountId: AccountId;
}): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;
  let tasks: BatchTask[] = [];

  let license = await h.License.getDoc(p.licenseId);
  if (license) {
    tasks.push(
      await addNoteToLicense({
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        licenseId: p.licenseId,
        noteText: `Unlinking license. ${p.humanReadableReasonForAction}`
      })
    );
  } else {
    // This could occur if we manually deleted the license
    getUniversalHelpers().olliePipe.emitEvent({ type: "error-missing-license-when-unlinking", payload: p });
  }

  // Unlink license from its entity
  if (p.entityType === "team") {
    tasks.push(await h.Team.update({ id: p.entityId, doc: { linkedLicenseId: h._MagicDeleteValue } }, { returnBatchTask: true }));
  } else if (p.entityType === "org") {
    tasks.push(await h.Org.update({ id: p.entityId, doc: { linkedLicenseId: h._MagicDeleteValue } }, { returnBatchTask: true }));

    // Check all teams belonging to an org. They will get unlinked as well.
    let t1 = await h.Team.query({ where: [{ orgId: ["==", p.entityId] }] });
    for (let i = 0; i < t1.docs.length; i++) {
      const team = t1.docs[i];
      if (team.linkedLicenseId === p.licenseId) {
        tasks = [
          ...tasks,
          ...(await unlinkLicense({
            actionPerformedByAccountId: p.actionPerformedByAccountId,
            entityId: team.id,
            entityType: "team",
            licenseId: p.licenseId,
            humanReadableReasonForAction: `Parent org is unlinking from a license. Team will be unlinked from license as well.`
          }))
        ];
      }
    }
  } else if (p.entityType === "account") {
    tasks.push(
      await h.AccountPrivate.update({ id: p.entityId, doc: { linkedLicenseId: h._MagicDeleteValue } }, { returnBatchTask: true })
    );
  }

  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function deleteLicense(p: { licenseId: string }): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;
  let tasks: BatchTask[] = [];

  const l = await h.License.getDoc(p.licenseId);
  if (!l) {
    throw new Error("Cannot delete license because it does not exists");
  }

  // Remove any links that might exists
  if (l.type === LICENSE_TYPES.account) {
    const a1 = await h.AccountPrivate.query({ where: [{ linkedLicenseId: ["==", p.licenseId] }] });
    await Promise.all(
      a1.docs.map(async i =>
        tasks.push(
          await h.AccountPrivate.update({ id: i.id, doc: { linkedLicenseId: h._MagicDeleteValue } }, { returnBatchTask: true })
        )
      )
    );
  }

  if (l.type === LICENSE_TYPES.org) {
    const a1 = await h.Org.query({ where: [{ linkedLicenseId: ["==", p.licenseId] }] });
    await Promise.all(
      a1.docs.map(async i =>
        tasks.push(await h.Org.update({ id: i.id, doc: { linkedLicenseId: h._MagicDeleteValue } }, { returnBatchTask: true }))
      )
    );
  }

  if (l.type === LICENSE_TYPES.team || l.type === LICENSE_TYPES.org) {
    const a1 = await h.Team.query({ where: [{ linkedLicenseId: ["==", p.licenseId] }] });
    await Promise.all(
      a1.docs.map(async i =>
        tasks.push(await h.Team.update({ id: i.id, doc: { linkedLicenseId: h._MagicDeleteValue } }, { returnBatchTask: true }))
      )
    );
  }

  // Remove any license history docs
  const r1 = await h.LicenseHistory.query({ where: [{ licenseId: ["==", p.licenseId] }] });
  if (r1.docs) {
    for (let i = 0; i < r1.docs.length; i++) {
      const his = r1.docs[i];
      tasks.push(await h.LicenseHistory.delete({ id: his.id }, { returnBatchTask: true }));
    }
  }

  // Remove item itself
  tasks.push(await h.License.delete({ id: p.licenseId }, { returnBatchTask: true }));

  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function deleteLicenseHistory(p: { licenseHistoryId: string }): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;
  let tasks: BatchTask[] = [];
  tasks.push(await h.LicenseHistory.delete({ id: p.licenseHistoryId }, { returnBatchTask: true }));
  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function addManualLicense(p: {
  entityType: "team" | "org" | "account";
  entityId: string;
  expireAtMS: number;
  startAtMS: number;
  isTrial: boolean;
  actionPerformedByAccountId: AccountId;
}): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;
  let tasks: BatchTask[] = [];

  // Generate license
  const licenseId = h.License.generateId();
  let license: License | null = null;
  if (p.entityType === "team") {
    license = {
      id: licenseId,
      type: LICENSE_TYPES.team,
      teamLicenseType: "manual",
      createdAtMS: Date.now(),
      startAtMS: p.startAtMS,
      expireAtMS: p.expireAtMS,
      status: "active",
      teamId: p.entityId,
      isTrial: p.isTrial
    };
  } else if (p.entityType === "org") {
    license = {
      id: licenseId,
      type: LICENSE_TYPES.org,
      createdAtMS: Date.now(),
      startAtMS: p.startAtMS,
      expireAtMS: p.expireAtMS,
      status: "active",
      orgId: p.entityId,
      orgLicenseType: "manual"
    };
  } else if (p.entityType === "account") {
    license = {
      id: licenseId,
      type: LICENSE_TYPES.account,
      accountLicenseType: "manual",
      createdAtMS: Date.now(),
      startAtMS: p.startAtMS,
      expireAtMS: p.expireAtMS,
      status: "active",
      accountId: p.entityId,
      isTrial: p.isTrial
    };
  }
  if (!license) {
    throw new Error("No manual license created in addManualLicense");
  }

  // Create license
  tasks.push(await h.License.add({ doc: license }, { returnBatchTask: true }));

  // Link license to entity
  tasks = [
    ...tasks,
    ...(await linkLicense({
      entityId: p.entityId,
      entityType: p.entityType,
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      humanReadableReasonForAction: `Manual license being created. Will link`,
      licenseId: license.id
    }))
  ];

  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function addStripeLicense(p: {
  teamId: string;
  stripeTransactionDetails: string;
  expireAtMS: number;
  startAtMS: number;
  actionPerformedByAccountId: AccountId;
  couponString?: string;
}): Promise<BatchTask[]> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;
  let tasks: BatchTask[] = [];

  const licenseId = h.License.generateId();

  // Generate license
  let license: License = {
    id: licenseId,
    type: LICENSE_TYPES.team,
    teamLicenseType: "stripe",
    createdAtMS: Date.now(),
    startAtMS: p.startAtMS,
    expireAtMS: p.expireAtMS,
    status: "active",
    teamId: p.teamId
  };

  // Create license
  tasks.push(await h.License.add({ doc: license }, { returnBatchTask: true }));

  tasks.push(
    await addNoteToLicense({
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      licenseId: licenseId,
      noteText: `Stripe Details. ${p.stripeTransactionDetails}`
    })
  );
  if (p.couponString) {
    tasks.push(
      await addNoteToLicense({
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        licenseId: licenseId,
        noteText: p.couponString
      })
    );
  }

  // Link license to entity
  tasks = [
    ...tasks,
    ...(await linkLicense({
      entityId: p.teamId,
      entityType: "team",
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      humanReadableReasonForAction: `Stripe license being created. Will link.`,
      licenseId: license.id
    }))
  ];

  return tasks;
  // SERVER_ONLY_TOGGLE
}

async function addInAppRevenueCatLicense(p: {
  accountId: string;
  revenueCatEntitlements: RevenueCatEntitlements[];
  humanReadableReasonForAction: string;
  actionPerformedByAccountId: AccountId;
}) {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;
  let tasks: BatchTask[] = [];

  const licenseId = h.License.generateId();

  const dateMSFarInTheFuture = moment().add(100, "years").valueOf();

  // Generate license
  let license: License = {
    id: licenseId,
    type: LICENSE_TYPES.account,
    accountLicenseType: "in-app-revenue-cat",
    createdAtMS: Date.now(),
    startAtMS: Date.now(),
    expireAtMS: dateMSFarInTheFuture,
    status: "active",
    accountId: p.accountId,
    derivedExternal: { activeRevenueCatEntitlements: normalizeRevenueCatEntitlementsFromArray(p.revenueCatEntitlements) }
  };

  // Create license
  tasks.push(await h.License.add({ doc: license }, { returnBatchTask: true }));

  tasks.push(
    await addNoteToLicense({
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      licenseId: licenseId,
      noteText: `Creation note: ${p.humanReadableReasonForAction}`
    })
  );

  // Link license to entity
  tasks = [
    ...tasks,
    ...(await linkLicense({
      entityId: p.accountId,
      entityType: "account",
      actionPerformedByAccountId: p.actionPerformedByAccountId,
      humanReadableReasonForAction: `RevenueCat license being created and then linked`,
      licenseId: license.id
    }))
  ];

  return tasks;
  // SERVER_ONLY_TOGGLE
}

export async function registerInAppRevenueCatCurrentEntitlements(p: {
  accountId: string;
  entitlements: RevenueCatEntitlements[];
  actionPerformedByAccountId: string;
}) {
  // SERVER_ONLY_TOGGLE

  // IMPORTANT NOTES
  // * This is self reported from the mobile device
  // * We do not validate the entitlements with revenue cat at this point as a precaution in case revenue cat has not propagated
  // * The entitlements will be validated with cronjobs each day

  const { appOllieFirestoreV2: h } = getServerHelpers();
  const [accountPrivate, activeLicenses] = await Promise.all([
    h.AccountPrivate.getDoc(p.accountId),
    h.License.query({ where: [{ accountId: ["==", p.accountId] }, { status: ["==", "active"] }] })
  ]);

  if (!activeLicenses) {
    throw new Error("Trouble running query");
  }

  if (!accountPrivate) {
    throw new Error("Missing account private in registerInAppRevenueCatCurrentEntitlements");
  }

  if (activeLicenses.docs.length > 1) {
    // Bad data state that should never happen but expire any extra ones
    for (let i = 1; i < activeLicenses.docs.length; i++) {
      await expireLicense({
        licenseId: activeLicenses.docs[i].id,
        actionPerformedByAccountId: "SYSTEM",
        humanReadableReasonForAction: "Account had multiple active licenses. This is unexpected so will clean up."
      });
    }
  }

  const lic1 = activeLicenses.docs[0];

  const userHasActiveSystemInAppRevenueCatLicenseThatIsLinked =
    activeLicenses.docs.length &&
    lic1.type === LICENSE_TYPES.account &&
    lic1.accountLicenseType === "in-app-revenue-cat" &&
    lic1.id === accountPrivate?.linkedLicenseId;

  // Handles when user has a license in the system. Several things can happen:
  //  a) nothing (since the license in the system matches the entitlements reported by revenueCat)
  //  b) update the system license (such as when they upgrade from an individual plan to a family plan)
  //  c) expire the system license (such as when they no longer have entitlements. Would likely happen more often in the nightly cronjob when licenses are validated)
  if (userHasActiveSystemInAppRevenueCatLicenseThatIsLinked) {
    // IGNORE FOLLOWING LINE (ts helper)
    if (lic1.type === LICENSE_TYPES.account && lic1.accountLicenseType === "in-app-revenue-cat") {
      if (p.entitlements.length > 0) {
        // Since they have at least 1 entitlement lets make sure current license entitlements match
        const x1 = jsonStableStringify(normalizeRevenueCatEntitlementsFromArray(p.entitlements));
        const x2 = jsonStableStringify(lic1.derivedExternal?.activeRevenueCatEntitlements || {});
        const entitlementsMatch = x1 === x2;
        if (!entitlementsMatch) {
          let localTasks: BatchTask[] = [];
          // Likely they have changed plans. So update the entitlements
          localTasks.push(
            await h.License.setPath(
              {
                id: lic1.id,
                pathObj: { derivedExternal: { activeRevenueCatEntitlements: true } },
                value: {
                  derivedExternal: { activeRevenueCatEntitlements: normalizeRevenueCatEntitlementsFromArray(p.entitlements) }
                }
              },
              { returnBatchTask: true }
            )
          );

          localTasks.push(
            await addNoteToLicense({
              actionPerformedByAccountId: p.actionPerformedByAccountId,
              licenseId: lic1.id,
              noteText: `Active entitlements have changed. Updating license. Original: ${x2}  Updated: ${x1}`
            })
          );

          await h._BatchRunner.executeBatch(localTasks);
          await refreshInAppRevenueCatLicenseRelationships({ hostAccountId: p.accountId });
        } else {
          // Since everything matches we can assume we are in a good state. So do nothing
        }
      } else {
        // Since they have no revenueCat entitlements and are linked to a revenue cat license we expire their current license

        // IGNORE FOLLOWING LINE (ts helper)
        if (accountPrivate.linkedLicenseId) {
          let localTasks: BatchTask[] = [];
          localTasks.push(
            ...(await expireLicense({
              licenseId: accountPrivate.linkedLicenseId,
              actionPerformedByAccountId: p.actionPerformedByAccountId,
              humanReadableReasonForAction:
                "On app registration the user lacks any revenueCat entitlements. Will expire current in app license"
            }))
          );
          await h._BatchRunner.executeBatch(localTasks);
          await refreshInAppRevenueCatLicenseRelationships({ hostAccountId: p.accountId });
        }
      }
    }
  } else {
    // Since they are not registering any entitlements and they are not linked to an in app license we return
    // Would occur when they have a trial/manual license
    if (p.entitlements.length === 0) {
      return;
    }

    // Since in app licenses are paid for they trump any other license type (trial, gift, an old in revenue cat one)
    // Deactivate any existing licenses and just start fresh
    let localTasks: BatchTask[] = [];
    if (lic1?.type === LICENSE_TYPES.account) {
      // IGNORE FOLLOWING LINE (ts helper)
      if (lic1.accountId === p.accountId) {
        const t = await expireLicense({
          licenseId: lic1.id,
          actionPerformedByAccountId: p.actionPerformedByAccountId,
          humanReadableReasonForAction: "Registering an in-app revenueCat license. All older licenses are being expired."
        });
        localTasks.push(...t);
      } else {
        // Since the license is not owned by the account we just unlink it
        const t = await unlinkLicense({
          entityId: p.accountId,
          entityType: "account",
          licenseId: lic1.id,
          actionPerformedByAccountId: p.actionPerformedByAccountId,
          humanReadableReasonForAction:
            "Registering an in-app revenueCat license. All older licenses are being unlinked from account."
        });
        localTasks.push(...t);
      }
      await h._BatchRunner.executeBatch(localTasks);
      // Now the account should no longer be associated with the old license. Register revenueCat license
    }

    // Now that they should not be linked to a license (old one may have been unlinked, or they may have never had a license) we add a license
    localTasks = [];
    localTasks.push(
      ...(await addInAppRevenueCatLicense({
        accountId: p.accountId,
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        humanReadableReasonForAction: "Registering a revenueCat license",
        revenueCatEntitlements: p.entitlements
      }))
    );
    await h._BatchRunner.executeBatch(localTasks);
    await refreshInAppRevenueCatLicenseRelationships({ hostAccountId: p.accountId });
  }

  // SERVER_ONLY_TOGGLE
}

export async function refreshInAppRevenueCatLicenseRelationships(p: { hostAccountId: string }): Promise<void> {
  // SERVER_ONLY_TOGGLE
  const { appOllieFirestoreV2: h } = getServerHelpers();
  const [hostAccountPrivate, activeLicensesOwnedByHost] = await Promise.all([
    h.AccountPrivate.getDoc(p.hostAccountId),
    h.License.query({ where: [{ accountId: ["==", p.hostAccountId] }, { status: ["==", "active"] }] })
  ]);

  if (!activeLicensesOwnedByHost) {
    throw new Error("Trouble running query in refreshInAppRevenueCatLicenseRelationships");
  }

  if (!hostAccountPrivate) {
    throw new Error("Unable to run refreshInAppRevenueCatLicenseRelationships because accountPrivate could not be found");
  }

  if (!hostAccountPrivate.linkedLicenseId) {
    throw new Error("Unable to run refreshInAppRevenueCatLicenseRelationships since the host lacks a linkedLicenseId");
  }

  const activeLicense = activeLicensesOwnedByHost.docs.find(lic => lic.id === hostAccountPrivate.linkedLicenseId);

  if (!activeLicense) {
    throw new Error("Unable to find a license owned linked to by host");
  }

  if (!(activeLicense.type === LICENSE_TYPES.account && activeLicense.accountLicenseType === "in-app-revenue-cat")) {
    throw new Error(
      "Unable to run refreshInAppRevenueCatLicenseRelationships if the host linked license is not a revenue cat license"
    );
  }
  const activeRevenueCatLicense = activeLicense;

  // If by chance a user has multiple active licenses we deactivate all but the selected one
  // In theory this state should never exists
  if (activeLicensesOwnedByHost.docs.length > 1) {
    const licensesToDeactivate = activeLicensesOwnedByHost.docs.filter(l => l.id !== activeRevenueCatLicense.id);
    const tasks: BatchTask[] = [];
    for (let i = 0; i < licensesToDeactivate.length; i++) {
      const l = licensesToDeactivate[i];
      // Execute immediate to try and clean things up
      tasks.push(
        ...(await expireLicense({
          licenseId: l.id,
          humanReadableReasonForAction: "Multiple active licenses found for a user. Deactivating some of them.",
          actionPerformedByAccountId: p.hostAccountId
        }))
      );
    }
    await h._BatchRunner.executeBatch(tasks);
  }

  // Find all the accounts linked to license through family plan
  const accountPrivatesLinkedToActiveRevenueCatLicenseMinusOwner = (
    await h.AccountPrivate.query({
      where: [{ linkedLicenseId: ["==", activeRevenueCatLicense.id] }]
    })
  ).docs.filter(ap => ap.id !== p.hostAccountId);

  // If the hostAccount no longer has a family entitlement then unlink other accounts and exit
  if (!activeRevenueCatLicense.derivedExternal?.activeRevenueCatEntitlements[RevenueCatEntitlements.premiumFamily]) {
    const localTasks: BatchTask[] = [];
    for (let i = 0; i < accountPrivatesLinkedToActiveRevenueCatLicenseMinusOwner.length; i++) {
      let ap = accountPrivatesLinkedToActiveRevenueCatLicenseMinusOwner[i];
      localTasks.push(
        ...(await unlinkLicense({
          actionPerformedByAccountId: p.hostAccountId,
          entityId: ap.id,
          entityType: "account",
          humanReadableReasonForAction: "Owner of license no longer has family entitlement",
          licenseId: activeRevenueCatLicense.id
        }))
      );
    }
    await h._BatchRunner.executeBatch(localTasks);
    return;
  }

  const tasks: BatchTask[] = [];

  // Remove people that are connected to an license but the license holder does not claim them
  for (let i = 0; i < accountPrivatesLinkedToActiveRevenueCatLicenseMinusOwner.length; i++) {
    let ap = accountPrivatesLinkedToActiveRevenueCatLicenseMinusOwner[i];
    if (!hostAccountPrivate.licenseFamilyShare?.accountIds[ap.id]) {
      tasks.push(
        ...(await unlinkLicense({
          actionPerformedByAccountId: p.hostAccountId,
          entityId: ap.id,
          entityType: "account",
          licenseId: activeRevenueCatLicense.id,
          humanReadableReasonForAction: `${ap.id} it not claimed by the license host`
        }))
      );
    }
  }

  const accountIdsClaimedByHostButAreNotLinkedToLicense = Object.keys(
    hostAccountPrivate.licenseFamilyShare?.accountIds || {}
  ).filter(id => {
    const isLinkedToHost = !!accountPrivatesLinkedToActiveRevenueCatLicenseMinusOwner.find(ap => ap.id === id);
    return !isLinkedToHost;
  });

  const accountPrivatesClaimedByHostButAreNotLinkedToLicense = await h.AccountPrivate.getDocs(
    accountIdsClaimedByHostButAreNotLinkedToLicense
  );
  await Promise.all(
    accountPrivatesClaimedByHostButAreNotLinkedToLicense.map(async ap => {
      if (!ap) {
        return;
      }

      if (!ap.linkedLicenseId) {
        // If they don't have an account we link them
        tasks.push(
          ...(await linkLicense({
            actionPerformedByAccountId: p.hostAccountId,
            entityId: ap.id,
            entityType: "account",
            humanReadableReasonForAction: `${ap.id} is part of the family plan for ${p.hostAccountId}. ${ap.id} currently lacks a linkedLicense so linking to revenue cat license`,
            licenseId: activeRevenueCatLicense.id
          }))
        );
      } else {
        const license = await h.License.getDoc(ap.linkedLicenseId);
        if (!license) {
          // This is a bad data state. Should scan for it and handle elsewhere
          return;
        }
        if (license.type === LICENSE_TYPES.account) {
          if (license.accountLicenseType === "manual") {
            tasks.push(
              ...(await linkLicense({
                actionPerformedByAccountId: p.hostAccountId,
                entityId: ap.id,
                entityType: "account",
                humanReadableReasonForAction: `${ap.id} is part of the family plan for ${p.hostAccountId}. ${ap.id} currently has a manual license. Will link to the revenue cat license`,
                licenseId: activeRevenueCatLicense.id
              }))
            );
            if (license.isTrial) {
              // Reset their ability to do a trial
              tasks.push(
                await h.AccountPrivate.update(
                  { id: ap.id, doc: { userInteractions: { hasUsedAccountTrial: h._MagicDeleteValue } } },
                  { returnBatchTask: true }
                )
              );
            }
          } else if (license.accountLicenseType === "in-app-revenue-cat") {
            if (license.accountId === p.hostAccountId) {
              // Basically this means they are linked to an old revenue cat license by the host
              // We link them to the new one
              tasks.push(
                ...(await linkLicense({
                  actionPerformedByAccountId: p.hostAccountId,
                  entityId: ap.id,
                  entityType: "account",
                  humanReadableReasonForAction: `${ap.id} is part of the family plan for ${p.hostAccountId}. ${ap.id} currently is linked to a license owned by ${p.hostAccountId}. Assume its an old linking. Update and link to current revenue cat license`,
                  licenseId: activeRevenueCatLicense.id
                }))
              );
            } else {
              // Since that person is linked a license owned by another person we remove as an account claimed by the host
              tasks.push(
                await h.AccountPrivate.update(
                  { id: p.hostAccountId, doc: { licenseFamilyShare: { accountIds: { [ap.id]: h._MagicDeleteValue } } } },
                  { returnBatchTask: true }
                )
              );
            }
          }
        } else {
          // Weird they are linked to a non-account license.
          // Is a data state that should not exists
        }
      }
    })
  );

  await h._BatchRunner.executeBatch(tasks);
  // SERVER_ONLY_TOGGLE
}

// *************************
// Helper Fns
// *************************

async function ensureLicenseExists(licenseId: string): Promise<License> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;

  const license = await h.License.getDoc(licenseId);
  if (!license) {
    throw new Error(`Cannot find license. Id: ${licenseId}`);
  }

  return license;
  // SERVER_ONLY_TOGGLE
}

async function getCurrentEntityLinkedLicenseId(p: {
  entityType: "team" | "org" | "account";
  entityId: string;
}): Promise<string | undefined> {
  // SERVER_ONLY_TOGGLE
  const h = getUniversalHelpers().ollieFirestoreV2;

  let currentEntityLicenseId: string | undefined;
  if (p.entityType === "team") {
    const team = await h.Team.getDoc(p.entityId);
    if (team) {
      currentEntityLicenseId = team.linkedLicenseId;
    } else {
      throw new Error(`Cannot find entity for getCurrentEntityLicenseId. Type: ${p.entityType}  Id:${p.entityId} `);
    }
  } else if (p.entityType === "org") {
    const org = await h.Org.getDoc(p.entityId);
    if (org) {
      currentEntityLicenseId = org.linkedLicenseId;
    } else {
      throw new Error(`Cannot find entity for getCurrentEntityLicenseId. Type: ${p.entityType}  Id:${p.entityId} `);
    }
  } else if (p.entityType === "account") {
    const accountPrivate = await h.AccountPrivate.getDoc(p.entityId);
    if (accountPrivate) {
      currentEntityLicenseId = accountPrivate.linkedLicenseId;
    } else {
      throw new Error(`Cannot find entity for getCurrentEntityLicenseId. Type: ${p.entityType}  Id:${p.entityId} `);
    }
  }

  return currentEntityLicenseId;
  // SERVER_ONLY_TOGGLE
}

export function normalizeRevenueCatEntitlementsFromArray(p: RevenueCatEntitlements[]): { [e in RevenueCatEntitlements]?: true } {
  const i: { [e in RevenueCatEntitlements]?: true } = {};
  const x = p.reduce((a, v) => {
    a[v] = true;
    return a;
  }, i);
  return x;
}

// i18n certified - complete

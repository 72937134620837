import { OrgId, TeamId, AccountId } from "@ollie-sports/models";
import { getUniversalHelpers } from "../helpers";
import { BatchTask } from "@ollie-sports/firebase";
import { linkLicense, unlinkLicense } from "./license-utils";
import { updateDerivedForTeam } from "./team-utils";

export async function joinTeamToOrgOrRemoveTeamFromOrg(
  p: {
    teamId: TeamId;
    actionPerformedByAccountId: AccountId;
  } & ({ type: "join"; orgId: OrgId } | { type: "remove" })
): Promise<void> {
  // SERVER_ONLY_TOGGLE
  const { ollieFirestoreV2: h } = getUniversalHelpers();
  let tasks: BatchTask[] = [];
  // Make sure orgId exists
  let org = p.type === "join" ? await h.Org.getDoc(p.orgId) : null;
  if (p.type === "join" && !org) {
    throw new Error("Org does not exists with that id");
  }
  let team = await h.Team.getDoc(p.teamId);
  if (!team) {
    throw new Error("Team does not exists with that id");
  }

  if (
    team.linkedLicenseId &&
    (p.type === "remove" || (p.type === "join" && org && team.linkedLicenseId != org.linkedLicenseId))
  ) {
    // Unlink from old license
    tasks = [
      ...tasks,
      ...(await unlinkLicense({
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        entityId: p.teamId,
        entityType: "team",
        humanReadableReasonForAction:
          p.type === "join" ? `Joining another org. Will unlink old license. New Org: ${p.orgId}` : `Removing team from org`,
        licenseId: team.linkedLicenseId
      }))
    ];
  }

  tasks.push(
    await h.Team.update(
      { id: p.teamId, doc: { orgId: p.type === "join" ? p.orgId : h._MagicDeleteValue } },
      { returnBatchTask: true }
    )
  );

  // Check if org has license. Link to license if it does
  if (p.type === "join" && org && org.linkedLicenseId) {
    tasks = [
      ...tasks,
      ...(await linkLicense({
        actionPerformedByAccountId: p.actionPerformedByAccountId,
        entityId: p.teamId,
        entityType: "team",
        humanReadableReasonForAction: `Joining team to org. TeamId: ${p.teamId}`,
        licenseId: org.linkedLicenseId
      }))
    ];
  }

  await h._BatchRunner.executeBatch(tasks);
  await updateDerivedForTeam({ executeImmediate: true, teamId: p.teamId });
  // SERVER_ONLY_TOGGLE
}

// i18n certified - complete

export function itemArrayToMap<T>(items: T[]): { [id: string]: T } {
  return items.reduce((acc: Record<string, T>, val) => {
    // @ts-ignore
    if (!val.id) {
      console.error("No id found. Unable to convert the array of items to a map. Item: " + JSON.stringify(val));
    }
    // @ts-ignore
    acc[val.id] = val;
    return acc;
  }, {});
}

export function pluck<T>(arr: T[], id: string, warnOnDuplicates = true): T {
  let filtered = arr.filter((i: any) => i.id === id);
  if (filtered.length === 0) {
    throw new Error(`Unable to pluck item from array. No match found. Id: ${id}`);
  }
  if (filtered.length > 1 && warnOnDuplicates) {
    console.warn(`Found multiple matches when plucking an item. Id: ${id}`);
  }

  return filtered[0];
}

export function isDevelopmentEnv(): boolean {
  const tempGlobal: any = global || {};
  return tempGlobal.__DEV__ || global?.process?.env?.NODE_ENV === "development";
}

// i18n certified - complete

import { getUniversalHelpers } from "../helpers";

export function getSimpleNotificationRef(p: { accountId: string }) {
  const { app } = getUniversalHelpers();
  return app.database().ref(`simpleNotification/${p.accountId}`);
}

export function getSimpleHeartBeatV2(p: { accountId: string; deviceId: string }) {
  const { app } = getUniversalHelpers();
  return app.database().ref(`simpleHeartBeatV2/${p.accountId}/${p.deviceId}`);
}

export function getDeviceRegistrationRefV2(p: { accountId: string; deviceId: string }) {
  const { app } = getUniversalHelpers();
  return app.database().ref(`deviceRegistrationV2/${p.accountId}/${p.deviceId}`);
}

export function getAllDeviceRegistrationsRefV2(p: { accountId: string }) {
  const { app } = getUniversalHelpers();
  return app.database().ref(`deviceRegistrationV2/${p.accountId}`);
}

// i18n certified - complete

import _ from "lodash";
import { Account, Player, PlayerBundle, PrettyPlayer } from "@ollie-sports/models";
import { fetchPrettyPlayerBundle, fetchPrettyPlayerBundleFromPlayerBundleAndAccounts } from "./player-bundle-utils";
import { getUniversalHelpers } from "../helpers";
import { ObjectValues } from "../utils";

export async function fetchPrettyPlayer(playerObj: Player | null): Promise<PrettyPlayer | undefined> {
  const { ollieFirestoreV2: h } = getUniversalHelpers();

  if (!playerObj) {
    return undefined;
  }

  try {
    // If no linked profile we just use the data on the player object
    if (!playerObj.linkedPlayerBundleId) {
      return {
        player: playerObj,
        derived: {
          accountInfo: playerObj.virtualAthleteAccount,
          accountInfoSource: "player"
        }
      };
    }

    let playerBundle = await h.PlayerBundle.getDoc(playerObj.linkedPlayerBundleId);
    if (!playerBundle) {
      throw new Error("Unable to find playerBundle");
    }
    let PrettyPlayerBundle = await fetchPrettyPlayerBundle(playerBundle);

    if (!PrettyPlayerBundle) {
      throw new Error("No pretyPlayerBundlefound " + playerObj.id);
    }

    if (PrettyPlayerBundle.derived.accountInfoSource === "playerBundle") {
      return {
        player: playerObj,
        derived: {
          accountInfo: PrettyPlayerBundle.derived.accountInfo,
          accountInfoSource: "playerBundle",
          playerBundle
        }
      };
    } else if (PrettyPlayerBundle.derived.accountInfoSource === "account") {
      return {
        player: playerObj,
        derived: {
          accountInfo: PrettyPlayerBundle.derived.accountInfo,
          accountInfoSource: "account",
          playerBundle
        }
      };
    } else {
      throw new Error("Trouble running fetchPrettyPlayer" + playerObj.id);
    }
  } catch (e) {
    console.error("Error running fetchPrettyPlayer", e);
    return {
      player: playerObj,
      derived: {
        accountInfo: playerObj.virtualAthleteAccount,
        accountInfoSource: "player"
      }
    };
  }
}

export async function fetchPrettyPlayerList(playerObjs: Player[]) {
  let vals = await Promise.all(playerObjs.map(p => fetchPrettyPlayer(p)));
  return _.sortBy(vals, "derived.accountInfo.firstName") as PrettyPlayer[];
}

export function fetchPrettyPlayersFromPlayersAndPlayerBundlesAndAccounts(
  players: Record<string, Player>,
  playerBundles: Record<string, PlayerBundle>,
  accounts: Record<string, Account>
): Record<string, PrettyPlayer> {
  return Object.values(players).reduce((acc, player) => {
    // If no linked profile we just use the data on the player object
    if (!player.linkedPlayerBundleId) {
      acc[player.id] = {
        player: player,
        derived: {
          accountInfo: player.virtualAthleteAccount,
          accountInfoSource: "player"
        }
      };
    } else {
      let playerBundle = playerBundles[player.linkedPlayerBundleId];
      if (!playerBundle) {
        acc[player.id] = {
          player: player,
          derived: {
            accountInfo: player.virtualAthleteAccount,
            accountInfoSource: "player"
          }
        };
      }

      let PrettyPlayerBundle = fetchPrettyPlayerBundleFromPlayerBundleAndAccounts(playerBundle, accounts);

      if (!PrettyPlayerBundle) {
        acc[player.id] = {
          player: player,
          derived: {
            accountInfo: player.virtualAthleteAccount,
            accountInfoSource: "player"
          }
        };
      } else {
        if (PrettyPlayerBundle.derived.accountInfoSource === "playerBundle") {
          acc[player.id] = {
            player: player,
            derived: {
              accountInfo: PrettyPlayerBundle.derived.accountInfo,
              accountInfoSource: "playerBundle",
              playerBundle
            }
          };
        } else if (PrettyPlayerBundle.derived.accountInfoSource === "account") {
          acc[player.id] = {
            player: player,
            derived: {
              accountInfo: PrettyPlayerBundle.derived.accountInfo,
              accountInfoSource: "account",
              playerBundle
            }
          };
        } else {
          acc[player.id] = {
            player: player,
            derived: {
              accountInfo: player.virtualAthleteAccount,
              accountInfoSource: "player"
            }
          };
        }
      }
    }
    return acc;
  }, {} as Record<string, PrettyPlayer>);
}

// i18n certified - complete
